var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.RESELLER')) + " (*)"),"placeholder":_vm.$t('COMMON.RESELLER')}},[_c('reseller-selector',{attrs:{"reseller":_vm.organization.reseller.id,"filterable":true,"showAll":false},on:{"resellerChanged":function (resellerId) {
          _vm.organization.reseller.id = resellerId;
          _vm.organization.owner.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reseller}}),_c('image-selector',{attrs:{"defaultImage":_vm.organization.logo,"ressource_name":"organizations","ressource_id":_vm.organization.id ? _vm.organization.id : 0,"field":"logo"},on:{"imageChanged":function (file_url) {
        _vm.organization.logo = file_url;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.NAME')) + " (*)"),"placeholder":_vm.$t('COMMON.NAME')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.name),callback:function ($$v) {_vm.$set(_vm.organization, "name", $$v)},expression:"organization.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),_c('base-input',{attrs:{"type":"textarea","label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.excerpt),callback:function ($$v) {_vm.$set(_vm.organization, "excerpt", $$v)},expression:"organization.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.EMAIL')) + " (*)"),"placeholder":_vm.$t('COMMON.EMAIL')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.email),callback:function ($$v) {_vm.$set(_vm.organization, "email", $$v)},expression:"organization.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.PHONE')) + " (*)")}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.organization.phone},on:{"phoneNumberChanged":function (phone) {
          _vm.organization.phone = phone;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ADDRESS')) + " (*)"),"placeholder":_vm.$t('COMMON.ADDRESS')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.address),callback:function ($$v) {_vm.$set(_vm.organization, "address", $$v)},expression:"organization.address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}}),_c('taxes-selector',{attrs:{"label":_vm.$t('COMMON.DEFAULT_TAXES'),"taxes":_vm.organization.taxes},on:{"taxesChanged":function (taxes) {
        _vm.organization.taxes = taxes;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.taxes}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ROLES))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.OWNER')) + " (*)"),"placeholder":_vm.$t('COMMON.OWNER')}},[_c('user-selector',{attrs:{"user":_vm.organization.owner.id,"filterable":true,"showAll":false,"filterResellerNull":_vm.organization.id ? false : true,"filterOrganization":_vm.organization.id ? _vm.organization.id : null,"filterOrganizationNull":_vm.organization.id ? false : true},on:{"userChanged":function (userId) {
          _vm.organization.owner.id = userId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.owner}}),_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_ADDRESS")))]),_c('billing-informations-form',{attrs:{"billingInformationsData":_vm.organization,"formErrors":_vm.formErrors},on:{"onChangeBillingInformations":_vm.billingInformationsChanged}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.organization.id ? _vm.$t("ORGANIZATIONS.EDIT_ORGANIZATION") : _vm.$t("ORGANIZATIONS.ADD_ORGANIZATION"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }