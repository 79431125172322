<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
      :label="`${$t('COMMON.RESELLER')} (*)`"
      :placeholder="$t('COMMON.RESELLER')"
    >
      <reseller-selector
        :reseller="organization.reseller.id"
        :filterable="true"
        :showAll="false"
        @resellerChanged="
          (resellerId) => {
            organization.reseller.id = resellerId;
            organization.owner.id = null;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.reseller" />

    <image-selector
      :defaultImage="organization.logo"
      ressource_name="organizations"
      :ressource_id="organization.id ? organization.id : 0"
      field="logo"
      @imageChanged="
        (file_url) => {
          organization.logo = file_url;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="organization.name"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <base-input
      type="textarea"
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <html-editor v-model="organization.excerpt" @change="onFormChanged()">
      </html-editor>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <base-input
      :label="`${$t('COMMON.EMAIL')} (*)`"
      :placeholder="$t('COMMON.EMAIL')"
      v-model="organization.email"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.email" />

    <base-input :label="`${$t('COMMON.PHONE')} (*)`">
      <phone-number-input
        :phoneNumber="organization.phone"
        @phoneNumberChanged="
          (phone) => {
            organization.phone = phone;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.phone" />

    <base-input
      :label="`${$t('COMMON.ADDRESS')} (*)`"
      :placeholder="$t('COMMON.ADDRESS')"
      v-model="organization.address"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.address" />

    <taxes-selector
      :label="$t('COMMON.DEFAULT_TAXES')"
      :taxes="organization.taxes"
      @taxesChanged="
        (taxes) => {
          organization.taxes = taxes;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.taxes" />

    <base-input
      :label="`${$t('COMMON.OWNER')} (*)`"
      :placeholder="$t('COMMON.OWNER')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
    >
      <user-selector
        :user="organization.owner.id"
        :filterable="true"
        :showAll="false"
        :filterResellerNull="organization.id ? false : true"
        :filterOrganization="organization.id ? organization.id : null"
        :filterOrganizationNull="organization.id ? false : true"
        @userChanged="
          (userId) => {
            organization.owner.id = userId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.owner" />

    <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>

    <billing-informations-form
      :billingInformationsData="organization"
      :formErrors="formErrors"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          organization.id
            ? $t("ORGANIZATIONS.EDIT_ORGANIZATION")
            : $t("ORGANIZATIONS.ADD_ORGANIZATION")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import TaxesSelector from "@/components/TaxesSelector.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    ResellerSelector,
    UserSelector,
    ImageSelector,
    TaxesSelector,
    BillingInformationsForm,
    [Select.name]: Select,
    [Option.name]: Option,
    PhoneNumberInput,
  },

  mixins: [formMixin],

  props: ["organizationData", "formErrors", "loading"],

  data() {
    return {
      organization: { ...this.organizationData },
      permissions: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      const permissionsData = [];
      for (const permissionId in this.permissionsModel) {
        if (this.permissionsModel[permissionId].checked) {
          permissionsData.push({ type: "permissions", id: permissionId });
        }
      }
      this.organization.permissions = permissionsData;
      this.$emit("organizationSubmitted", this.organization);
    },

    billingInformationsChanged(billingInformations) {
      this.organization = { ...this.organization, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    organizationData(organizationData) {
      if (organizationData) {
        this.organization = {
          ...this.organization,
          ...cloneDeep(organizationData),
        };
      }
    },
  },
};
</script>
